import * as React from "react"
import { Input } from "antd"
import { TextAreaProps } from "antd/es/input"
import { TextTypography } from "../Typography/TextTypography/TextTypography"
import { TextColor } from "../Typography/typography.service"

interface TextAreaFieldProps extends TextAreaProps {
  error?: any
  name: any
  className?: string
  id?: string
  fontSize?: 10 | 12 | 14 | 16 | 18 | 20
}

const TextAreaField: React.FC<TextAreaFieldProps> = ({
  fontSize = 14,
  ...props
}) => {
  return (
    <div className={props.className}>
      <Input.TextArea
        {...props}
        id={props.id}
        name={props.name}
        className={`rounded-sm font-averta-std-regular py-3 px-4 text-secondary focus:shadow-none placeholder-lightGrey border border-grey text-${fontSize}`}
      />
      {props.error && (
        <TextTypography className="ml-1 mt-1" color={TextColor.DANGER}>
          {props.error}
        </TextTypography>
      )}
    </div>
  )
}

export default TextAreaField
