import React from "react"
import AppFooter from "../components/AppFooter/AppFooter"
// import AppHeader from "../components/AppHeader/AppHeader"
import AppHeader from "../components/V2/AppHeader"
import ContactUsPage from "../components/ContactUs/ContactUsPage"
import FaviconHeader from "../components/FaviconHeader/FaviconHeader"

const ContactPage: React.FC = () => {
  return (
    <main className="w-full h-full bg-lightBackground">
      <FaviconHeader />
      {/* <AppHeader className="transparent-bg-color" /> */}
      <AppHeader />
      <ContactUsPage />
      <AppFooter />
    </main>
  )
}

export default ContactPage
