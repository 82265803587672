import { Formik } from "formik"
import * as Yup from "yup"
import React, { useState } from "react"
import { contactUs } from "../../services/api.service"
import TextField from "../SharedResources/TextField/TextField"
import { TextTypography } from "../SharedResources/Typography/TextTypography/TextTypography"
import { DomeSpinner } from "../SharedResources/DomeSpinner/DomeSpinner"
import TextAreaField from "../SharedResources/TextAreaField/TextAreaField"
import DomeButton from "../SharedResources/Button/Button"
import "./ContactUsPage.scss"
import { FontStyle } from "../SharedResources/Typography/typography.service"
import { displayNotification } from "../../services/toast.service"
import LabelTypography from "../SharedResources/Typography/LabelTypography/LabelTypography"

const ContactUsPage: React.FC = () => {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)

  const ContactUsFormSchema = Yup.object().shape({
    email: Yup.string().email("Invalid Email").required("Email is required"),
    name: Yup.string().required("Name is required"),
    message: Yup.string().required("Message is required"),
  })

  return (
    <section className="contact-page-container">
      {isSubmitted ? (
        <article className={`flex flex-col text-center py-28 px-4`}>
          <TextTypography className="text-4xl" fontStyle={FontStyle.BOLD}>
            Thanks for contacting us
          </TextTypography>
          <div className="flex flex-col mt-6">
            <TextTypography className="text-sm text-black">
              A Dome Events team member will be in touch
            </TextTypography>
            <TextTypography className="text-sm text-black">
              shortly to discuss your inquiry.
            </TextTypography>
          </div>
          <TextTypography className="text-sm text-black mt-6">
            We look forward to connecting with you.
          </TextTypography>
        </article>
      ) : (
        <article className="mx-auto pt-28 px-8 max-w-4xl">
          <Formik
            initialValues={{ email: "", name: "", message: "" }}
            validationSchema={ContactUsFormSchema}
            onSubmit={(values) => {
              return contactUs(values)
                .then(() => {
                  setIsSubmitted(true)
                })
                .catch((err) => {
                  return displayNotification(err.message, true)
                })
            }}
          >
            {(formProps) => (
              <form
                onSubmit={formProps.handleSubmit}
                className="flex flex-col space-y-12 sm:space-y-0 sm:flex-row sm:space-x-12"
              >
                <div className="flex flex-col w-full">
                  <TextTypography
                    className={"text-2xl mb-4"}
                    fontStyle={FontStyle.BOLD}
                  >
                    Contact Us
                  </TextTypography>
                  <TextTypography className={"text-sm"}>
                    Please let us know your name and email.
                  </TextTypography>
                  <TextTypography className={"text-sm"}>
                    We’ll get back to you as soon as possible.
                  </TextTypography>
                </div>
                <div className="w-full">
                  <TextField
                    name={"name"}
                    label="Name"
                    onChange={formProps.handleChange}
                    onBlur={formProps.handleBlur}
                    error={
                      formProps.errors.name &&
                      formProps.touched.name &&
                      formProps.errors.name
                    }
                    containerClassName={"mb-2"}
                    placeholder={"Name"}
                  />
                  <TextField
                    name={"email"}
                    label="Email"
                    onChange={formProps.handleChange}
                    onBlur={formProps.handleBlur}
                    containerClassName={"mb-2"}
                    error={
                      formProps.errors.email &&
                      formProps.touched.email &&
                      formProps.errors.email
                    }
                    placeholder={"Email"}
                  />
                  <LabelTypography labelText="Message" className="mb-2" />
                  <TextAreaField
                    name={"message"}
                    rows={2}
                    onChange={formProps.handleChange}
                    onBlur={formProps.handleBlur}
                    error={
                      formProps.errors.message &&
                      formProps.touched.message &&
                      formProps.errors.message
                    }
                    className={"mb-2"}
                    placeholder={"Message"}
                  />

                  <DomeButton
                    htmlType={"submit"}
                    disabled={formProps.isSubmitting}
                    type={"primary"}
                    isFullWidthButton={true}
                  >
                    {formProps.isSubmitting ? (
                      <DomeSpinner
                        className={"white-spinner-color"}
                        size={"small"}
                      />
                    ) : (
                      "Contact Us"
                    )}
                  </DomeButton>
                </div>
              </form>
            )}
          </Formik>
        </article>
      )}
    </section>
  )
}

export default ContactUsPage
